import React from 'react';
import './SocialNetwork.css';

function SocialNetwork() {
    return (
        <div className="social-icons">
            <a className="social-icon" rel="noreferrer" href="https://www.linkedin.com/in/rafael-monteiro-80ba31174/" target="_blank"><img src="/linkedin.png" alt="linkedin" /></a>
            <a className="social-icon" rel="noreferrer" href="https://github.com/rafamon" target="_blank"><img src="/git.png" alt="git" /></a>
            <a className="social-icon" rel="noreferrer" href="discord://users/@me/Rafix" target="_blank"><img src="/discord.png" alt="discord" /></a>
            <a className="social-icon" href="mailto:rafixmonteiro@gmail.com" ><img src="/email.png" alt="email" /></a>
        </div>
    );
}

export default SocialNetwork;