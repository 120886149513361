import React from 'react';
import './RightColumn.css';
import RightDefault from './RightDefault';
import { Route, Routes } from 'react-router-dom';
import Blog from './Blog';

function RightColumn() {

  return (

    <div className="right-column">
      <Routes>
        <Route path="/" element={<RightDefault />} />
        <Route path="/blog/*" element={<Blog />} />
      </Routes>
    </div>

  );
}

export default RightColumn;
