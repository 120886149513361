import React from 'react';
import Career from './Career';
import './RightDefault.css';
import Project from './Project';

function RightDefault() {

  return (
    <div className="right-default">
      <div id="about">
      <div className="title-section">
          <div className="title-header">About</div><div className="line"></div>

        </div>
        <p>Welcome to my page!</p>
        <p>My journey into the world of coding began at the age of 14 when I embarked on the ambitious quest of developing my own PlayStation Portable game using LUA language. Since then, my passion for coding has only grown stronger, propelling me to continuously explore and master new technologies. Whether it's coding for school projects, professional endeavors, or tinkering with personal projects in my free time, I've remained dedicated to honing my skills and pushing the boundaries of what's possible in the realm of development.</p>

        <p>Here, you'll discover insights into my career journey as well as my social links and contacts. Also, don't forget to check out my blog, where I share my tech adventures and insights gained from years of hands-on experience.</p>

        <p>This page was developed with React and is hosted on a virtual private server running CentOS.</p>

        <p>Thank you for visiting.</p>
      </div>
      <div>
        <div className="title-section" id="career">
          <div className="title-header">Career</div><div className="line"></div>

        </div>

        <Career year="2021-Now" employer="Banco Santander, Alter Solutions" position="Software Developer" description="Member of API core team, responsible to manage, implement new features and develop new core micro-services and their APIfication." techs={["Spring Boot", "IBM API Gateway", "Git", "GoCD"]} />
        <Career year="2020-2021" employer="Banco Santander, Accenture" position="Software Developer" description="Developed micro-services for a car insurance platform." techs={["Spring Boot", "Git", "GoCD", "Postman"]} />
        <Career year="2019-2020" employer="Banco Santander, Accenture" position="Software Developer" description="Developed multiple micro-services related to the implementation of PSD2 regulations as well as a back-office to manage and support the micro-services." techs={["Angular 8", "Postman", "Git", "GoCD", "Selenium", "Spring Boot", "SolR", "Kafka", "Postgres", "Oracle DB", "HBase", "Redis", "RabbitMQ"]} />

        <div className="title-section">
          <div className="title-header">Latest Post</div><div className="line"></div>

        </div>
        <Project />
      </div>

    </div>
  );
}

export default RightDefault;
