import React from 'react';
import './BlogList.css';
import BlogPost from './BlogPost';

function BlogList({ data }) {
    return (
        <div className="bloglist-wrap">
            {data.slice().reverse().map(post => (
                <BlogPost key={post.id} data={post} />
            ))}
        </div>
    );
}

export default BlogList;