import React from 'react';
import './BlogPost.css';
import { Link } from 'react-router-dom';

function BlogPost({ data }) {
    return (
        <Link to={`/blog/post/${data.id}`} className="blogpost-wrap link-no-style">
            <div className="post-image">
                <img src={`/blog/${data.image}`} alt={data.image} />
            </div>
            <div className="post-data">
                <div className='post-title'>
                    {data.title}<br />
                </div>
                <p>
                    {data.shortContent}
                </p>
            </div>
        </Link>
    );
}

export default BlogPost;