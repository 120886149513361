import React from 'react';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import './Home.css';

function Home() {
    return (
        <div className="content-container">
            <LeftColumn />
            <RightColumn />
        </div>

    );
}

export default Home;