import React from 'react';
import './BlogFull.css';
import { useParams } from 'react-router-dom';

function BlogFull({ data }) {

    const { postId } = useParams();
    const numericPostId = Number(postId);
    const post = data.find(post => post.id === numericPostId);
    
    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="blogfull-wrap">
            <h3>{post.title}</h3>
            
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
    );
}

export default BlogFull;