import React from 'react';
import './LeftColumn.css';
import SocialNetwork from './SocialNetwork.js';

function LeftColumn() {
  return (
    <div className="left-column">
      <h1 className="name">Rafael Monteiro</h1>
      <h2>Full stack developer</h2>
      <p className="left-description">Technology enthusiast, selftaught in multiple programming languages and different technologies. Always looking to improve and embrace new challenges.</p>
      <SocialNetwork />
    </div>
  );
}

export default LeftColumn;
