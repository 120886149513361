import React from 'react';
import './Tech.css';

function Tech({ tech }) {
  return (
    <div className="techs-wrap">
      {tech}
    </div>
  );
}

export default Tech;