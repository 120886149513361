import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Header() {
  return (
    <header>
      <nav>
        <ul>
          <li className="header-el"><HashLink smooth to="/#about" className="link-no-style">About</HashLink></li>
          <li className="header-el"><HashLink smooth to="/#career" className="link-no-style">Career</HashLink></li>
          <li className="header-el"> <Link to="/blog" className="link-no-style">Blog</Link></li>
          <li className="download-cv"><a href="/rafaelmonteiroCV.pdf" download className="link-no-style">Download CV</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;