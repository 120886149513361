import React from 'react';
import './Career.css';
import Tech from './Tech';

function Career({ year, employer, position, description, techs }) {
  return (
    <div className="career-wrap">
      <div className="career-year">
        <strong>{year}</strong>
      </div>
      <div className="career-content">
        <div className='career-title'>
          {employer}<br />
          {position}
        </div>
        <p>
          {description}
        </p>
        <div className='techs'>
          {Array.isArray(techs) && techs.map((tech, index) => (
            <Tech key={index} tech={tech} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Career;