import React from 'react';
import './Blog.css';
import blogData from '../data/blogData.json';
import BlogList from './BlogList';
import { Route, Routes } from 'react-router-dom';
import BlogFull from './BlogFull';

function Blog() {
  return (
    <div className="blog-wrap">
      <div className="title-section">
        <div className="title-header">Blog</div><div className="line"></div>
      </div>
      <Routes>
        <Route path="/post/:postId" element={<BlogFull data = {blogData} />} />
        <Route path="/" element={<BlogList data = {blogData} />} />
      </Routes>
    </div>
  );
}

export default Blog;