import React from 'react';
import './Project.css';
import blogData from '../data/blogData.json';
import BlogPost from './BlogPost';

function Project() {

  const lastPost = blogData[blogData.length - 1];

  return (
        <div className="bloglist-wrap">
                <BlogPost key={lastPost.id} data={lastPost} />
        </div>
  );
}

export default Project;